h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-text-color);
  font-weight: var(--heading-font-weight);
  margin-bottom: 0.75em;
  text-transform: uppercase;

  &:only-child {
    margin-bottom: 0;
  }
}

h1 {
  font-size: clamp(
    var(--h1-font-size-min),
    var(--h1-font-size-scaled),
    var(--h1-font-size-max)
  );
  line-height: var(--h1-line-height);
}

h2 {
  font-size: calc(var(--h2-font-size-base) * 0.8);
  line-height: var(--h2-line-height);

  @media (min-width: theme('screens.tablet-md')) {
    font-size: var(--h2-font-size-base);
  }
}

h3 {
  font-size: calc(var(--h3-font-size) * 0.8);
  line-height: var(--h3-line-height);

  @media (min-width: theme('screens.tablet-md')) {
    font-size: var(--h3-font-size);
  }
}

h4 {
  font-size: calc(var(--h4-font-size) * 0.8);
  line-height: var(--h4-line-height);

  @media (min-width: theme('screens.tablet-md')) {
    font-size: var(--h4-font-size);
  }
}

h5 {
  font-size: calc(var(--h5-font-size) * 0.8);
  line-height: var(--h5-line-height);

  @media (min-width: theme('screens.tablet-md')) {
    font-size: var(--h5-font-size);
  }
}

h6 {
  font-size: calc(var(--h6-font-size) * 0.8);
  line-height: var(--h6-line-height);

  @media (min-width: theme('screens.tablet-md')) {
    font-size: var(--h6-font-size);
  }
}

header p,
.section-header-copy {
  @apply text-base;

  @media (min-width: theme('screens.tablet-sm')) {
    @apply text-lg;
  }

  @media (min-width: theme('screens.tablet-md')) {
    @apply text-xl;
  }

  @media (min-width: theme('screens.tablet-lg')) {
    @apply text-2xl;
  }
}

.section-title {
  font-size: clamp(
    var(--section-title-font-size-min),
    var(--section-title-font-size-scaled),
    var(--section-title-font-size-max)
  );
  line-height: var(--section-title-line-height);
}

ul,
ol,
p,
span {
  color: var(--body-text-color);
  @apply text-base;
}

p {
  & + p {
    margin-top: 0.75em;
  }
}

strong {
  font-weight: theme('fontWeight.extrabold');
}
