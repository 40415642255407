@layer components {
  content {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: theme('maxWidth.site');
    padding-left: var(--content-padding-x--lg);
    padding-right: var(--content-padding-x--lg);
    width: 100%;

    @media (min-width: theme('screens.tablet-md')) {
      padding-left: var(--content-padding-x--lg);
      padding-right: var(--content-padding-x--lg);
    }
  }
}
