section {
  content {
    flex-direction: column;
    padding-bottom: var(--section-padding-y);
    padding-top: var(--section-padding-y);
  }

  &.overlaps-above {
    content {
      padding-top: 0;
    }
  }
}
