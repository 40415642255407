@layer components {
  card {
    background: white;
    border-radius: var(--card-border-radius);
    display: block;
  }

  card-section {
    display: block;
    padding: var(--card-padding-y-fluid) var(--card-padding-x-fluid);
    padding: var(--card-padding);
  }
}
