@layer components {
  .page-header {
    padding-top: var(--nav-height);

    content {
      padding-bottom: var(--section-padding-y);
      padding-top: var(--section-padding-y);
    }
  }

  .section-header {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--section-header-margin-bottom);
    width: 100%;

    &:only-child {
      margin-bottom: 0;
    }
  }
}
