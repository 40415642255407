@layer base {
  body {
    overflow-x: hidden;
  }

  :focus-visible {
    outline: 3px solid var(--blue-300);
    outline-offset: 3px;
  }
}
