body[data-page='index'] {
  header {
    content {
      height: 65vh;
      min-height: theme('spacing.400');
    }
  }

  #about {
    card-section {
      --card-padding-x-max: theme('spacing.80');
      --card-padding-x-fluid: 9%;
      --card-padding-y-max: theme('spacing.80');
      --card-padding-y-fluid: 12%;
    }
  }

  #strategies {
    li {
      &:nth-child(3n + 1) card {
        background-color: var(--darkblue-500);
      }

      &:nth-child(3n + 2) card {
        background-color: var(--darkblue-300);
      }

      &:nth-child(3n + 3) card {
        background-color: var(--gold-500);
      }
    }

    card {
      &::before {
        background: black;
        content: '';
        height: 100%;
        opacity: 0.12;
        position: absolute;
        right: 75%;
        top: 0;
        transform: skew(-15deg);
        width: 100%;
      }
    }
  }

  #principals {
    .photo-container {
      &::after {
        @apply bg-darkblue-500/40;
        content: '';
        height: 100%;
        left: 0;
        mix-blend-mode: soft-light;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }
  }

  #contact {
    .card-bg {
      &::after {
        content: '';
        height: 100%;
        left: 0;
        mix-blend-mode: multiply;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    li:first-child .card-bg::after {
      background-color: var(--darkblue-500);
      opacity: 0.9;
    }

    li:nth-child(2) .card-bg::after {
      background-color: var(--darkblue-300);
    }

    li:last-child .card-bg::after {
      background-color: var(--gold-500);
    }
  }
}
