@layer components {
  .columns,
  section-columns {
    display: grid;
    grid-template-columns: 1fr;
    gap: theme('spacing.80');
  }

  section-columns {
    width: 100%;

    @media (min-width: theme('screens.tablet-lg')) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
