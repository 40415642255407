@layer components {
  #header-nav {
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color 0.2s;
    width: 100%;
    z-index: 5;

    content {
      height: var(--nav-height);
      justify-content: space-between;
      position: relative;
    }

    .logo-link {
      display: block;
      transition: 0.15s;

      img {
        height: theme('spacing.50');
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .nav-links-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      width: 100vw;

      @media (min-width: theme('screens.nav')) {
        flex-direction: row;
        height: auto;
        pointer-events: all;
        position: static;
        width: auto;
      }

      &::before,
      &::after {
        border-color: transparent;
        border-style: solid;
        border-width: 3px;
        content: '';
        height: 16vmin;
        min-width: 16vmin;
        opacity: 0;
        position: absolute;
        transition: transform 0.2s cubic-bezier(0.15, 0, 0, 1), opacity 0.1s;
        width: 16vmin;
        z-index: 6;

        @media (min-width: theme('screens.nav')) {
          display: none;
        }
      }

      &::before {
        border-left-color: var(--lightblue-500);
        border-top-color: var(--lightblue-500);
        left: 8vmin;
        transform: translate(20%, 20%);
        top: 8vmin;
      }

      &::after {
        border-bottom-color: var(--lightblue-500);
        border-right-color: var(--lightblue-500);
        bottom: 8vmin;
        right: 8vmin;
        transform: translate(-20%, -20%);
      }
    }

    ul {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      opacity: 0;
      transform: scale(0.9);
      transition: 0.2s cubic-bezier(0.15, 0, 0, 1);

      @media (min-width: theme('screens.phone-lg')) {
        font-size: 1.25rem;
      }

      @media (min-width: theme('screens.tablet-sm')) {
        font-size: 1.5rem;
      }

      @media (min-width: theme('screens.nav')) {
        flex-direction: row;
        opacity: 1;
        transform: none;
        transition: none;
      }
    }

    li {
      margin: 0.75em 0;
      position: relative;

      &::after {
        border-bottom: 2px solid var(--lightblue-500);
        bottom: -0.75em;
        content: '';
        height: 1px;
        left: 50%;
        opacity: 0.5;
        position: absolute;
        transform: translateX(-50%);
        width: 2em;

        @media (min-width: theme('screens.nav')) {
          display: none;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      @media (min-width: theme('screens.nav')) {
        margin: 0;
      }

      a {
        display: block;
        color: white;
        text-transform: uppercase;
        letter-spacing: theme('letterSpacing.widest');
        padding: 0.5em 1em;
        transition: 0.1s;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .backdrop {
      background: var(--darkblue-500);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: scaleY(0);
      transition: 0.4s cubic-bezier(0.15, 0, 0, 1);
      width: 100%;

      @media (min-width: theme('screens.nav')) {
        display: none;
      }
    }

    button {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: theme('spacing.40');
      justify-content: center;
      min-width: theme('spacing.40');
      position: relative;
      width: theme('spacing.40');
      z-index: 6;

      span {
        background: white;
        height: theme('spacing.2');
        margin: theme('spacing.4') 0;
        transition: 0.3s cubic-bezier(0.15, 0, 0, 1);
        width: theme('spacing.30');
      }

      &:hover {
        span {
          &:first-child {
            transform: translateY(-1px);
          }

          &:last-child {
            transform: translateY(1px);
          }
        }
      }
    }

    &[data-nav-mobile-menu-open-value='true'] {
      .nav-links-container {
        pointer-events: all;

        &::before,
        &::after {
          opacity: 1;
          transform: none;
          transition: 0.6s 0.2s cubic-bezier(0.15, 0, 0, 1);
        }
      }

      .backdrop {
        transform: none;
      }

      ul {
        opacity: 1;
        transform: none;
        transition: 0.7s 0.1s cubic-bezier(0.15, 0, 0, 1);
      }

      button span {
        &:first-child {
          transform: translateY(5px);
        }

        &:last-child {
          transform: translateY(-5px);
        }
      }
    }

    &[data-nav-scrolled-value='true'] {
      background-color: var(--darkblue-600);
    }
  }

  @media (min-width: theme('screens.nav')) {
    #header-nav {
      ul {
        @apply static opacity-100 pointer-events-auto w-auto h-auto bg-transparent flex-row mr-[-0.5em];
      }

      a {
        @apply text-sm;
      }

      button {
        @apply hidden;
      }
    }
  }
}
