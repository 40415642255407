@import 'jit-refresh.css'; /* triggers frontend rebuilds */

/* Base/global styles */
@import 'tailwindcss/base';
@import './base.css';

/* Variables */
@import './variables.css';

/* Typography */
@import './typography/all.css';

/* Layout */
@import './layout/all.css';

/* Components */
@import 'tailwindcss/components';
@import './components/all.css';

/* Pages */
@import './pages/all.css';

/* Themes */
@import './themes/all.css';

/* Utilities */
@import 'tailwindcss/utilities';
@import './utilities.css';
