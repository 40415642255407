@layer utilities {
  .bg-fill {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .center-absolute {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .grid-span {
    grid-column: 1 / -1;
  }

  .overlapped-below {
    padding-bottom: var(--overlap-spacing);
  }

  .overlaps-above {
    margin-top: calc(var(--overlap-spacing) * -1);
  }

  .no-site-transitions * {
    transition: none !important;

    &::before,
    &::after {
      transition: none !important;
    }
  }

  .place-center {
    align-items: center;
    justify-content: center;
  }

  .scroll-lock {
    overflow: hidden;
    position: relative;
  }

  .text-dark {
    color: var(--text-color-dark);
  }

  .text-gold {
    color: var(--text-color-gold);
  }

  .text-gray {
    color: var(--text-color-gray);
  }

  .text-light {
    color: var(--text-color-light);
  }
}
