.button {
  background-color: var(--button-bg-color-default);
  border-radius: var(--control-border-radius);
  color: var(--button-text-color-default);
  font-size: var(--control-font-size);
  min-height: var(--control-height);
  padding: var(--button-padding-y) var(--button-padding-x);
  transition: background-color 0.1s;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: var(--button-bg-color-default-hover);
  }

  &:active {
    background-color: var(--button-bg-color-default-active);
  }
}
