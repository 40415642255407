:root {
  /* COLORS ================================================================= */

  /* Dark blue */
  --darkblue-100: theme('colors.darkblue.100');
  --darkblue-200: theme('colors.darkblue.200');
  --darkblue-300: theme('colors.darkblue.300');
  --darkblue-400: theme('colors.darkblue.400');
  --darkblue-500: theme('colors.darkblue.500');
  --darkblue-600: theme('colors.darkblue.600');
  --darkblue-700: theme('colors.darkblue.700');
  --darkblue-800: theme('colors.darkblue.800');
  --darkblue-900: theme('colors.darkblue.900');

  /* Dark blue */
  --blue-100: theme('colors.blue.100');
  --blue-200: theme('colors.blue.200');
  --blue-300: theme('colors.blue.300');
  --blue-400: theme('colors.blue.400');
  --blue-500: theme('colors.blue.500');
  --blue-600: theme('colors.blue.600');
  --blue-700: theme('colors.blue.700');
  --blue-800: theme('colors.blue.800');
  --blue-900: theme('colors.blue.900');

  /* Blue */
  --blue-gray-100: theme('colors.blueGray.100');
  --blue-gray-200: theme('colors.blueGray.200');
  --blue-gray-300: theme('colors.blueGray.300');
  --blue-gray-400: theme('colors.blueGray.400');
  --blue-gray-500: theme('colors.blueGray.500');
  --blue-gray-600: theme('colors.blueGray.600');
  --blue-gray-700: theme('colors.blueGray.700');
  --blue-gray-800: theme('colors.blueGray.800');
  --blue-gray-900: theme('colors.blueGray.900');

  /* Light blue */
  --lightblue-100: theme('colors.lightblue.100');
  --lightblue-200: theme('colors.lightblue.200');
  --lightblue-300: theme('colors.lightblue.300');
  --lightblue-400: theme('colors.lightblue.400');
  --lightblue-500: theme('colors.lightblue.500');
  --lightblue-600: theme('colors.lightblue.600');
  --lightblue-700: theme('colors.lightblue.700');
  --lightblue-800: theme('colors.lightblue.800');
  --lightblue-900: theme('colors.lightblue.900');

  /* Gold */
  --gold-100: theme('colors.gold.100');
  --gold-200: theme('colors.gold.200');
  --gold-300: theme('colors.gold.300');
  --gold-400: theme('colors.gold.400');
  --gold-500: theme('colors.gold.500');
  --gold-600: theme('colors.gold.600');
  --gold-700: theme('colors.gold.700');
  --gold-800: theme('colors.gold.800');
  --gold-900: theme('colors.gold.900');

  /* Light gray */
  --lightgray-100: theme('colors.lightGray.100');
  --lightgray-200: theme('colors.lightGray.200');
  --lightgray-300: theme('colors.lightGray.300');
  --lightgray-400: theme('colors.lightGray.400');
  --lightgray-500: theme('colors.lightGray.500');
  --lightgray-600: theme('colors.lightGray.600');
  --lightgray-700: theme('colors.lightGray.700');
  --lightgray-800: theme('colors.lightGray.800');
  --lightgray-900: theme('colors.lightGray.900');

  /* Red */
  --red-100: theme('colors.red.100');
  --red-200: theme('colors.red.200');
  --red-300: theme('colors.red.300');
  --red-400: theme('colors.red.400');
  --red-500: theme('colors.red.500');
  --red-600: theme('colors.red.600');
  --red-700: theme('colors.red.700');
  --red-800: theme('colors.red.800');
  --red-900: theme('colors.red.900');

  /* Light gray */
  --green-100: theme('colors.green.100');
  --green-200: theme('colors.green.200');
  --green-300: theme('colors.green.300');
  --green-400: theme('colors.green.400');
  --green-500: theme('colors.green.500');
  --green-600: theme('colors.green.600');
  --green-700: theme('colors.green.700');
  --green-800: theme('colors.green.800');
  --green-900: theme('colors.green.900');

  /* Site colors */
  --color-primary: var(--darkblue-500);
  --color-secondary: var(--gold-500);

  /* Decorative */
  --line-color: var(--lightgray-500);

  /* ACCESSIBILITY ========================================================== */
  --focus-visible-shadow: 0 0 0 1px var(--blue-500), 0 0 0 4px var(--blue-200);

  /* TYPOGRAPHY ============================================================= */

  /* Colors */
  --text-color-dark: var(--darkblue-600);
  --text-color-gray: var(--blue-gray-600);
  --text-color-gold: var(--gold-500);
  --text-color-light: var(--blue-gray-300);

  /* Headings */
  --heading-text-color: var(--text-color-dark);
  --heading-font-weight: theme('fontWeight.extrabold');

  --h1-font-size-min: theme('fontSize.4xl');
  --h1-font-size-scaled: 12vw;
  --h1-font-size-max: theme('fontSize.8xl');
  --h1-line-height: theme('lineHeight.4xs');

  --h2-font-size-base: theme('fontSize.5xl');
  --h2-line-height: theme('lineHeight.3xs');

  --h3-font-size: theme('fontSize.4xl');
  --h3-line-height: theme('lineHeight.2xs');

  --h4-font-size: theme('fontSize.2xl');
  --h4-line-height: theme('lineHeight.xs');

  --h5-font-size: theme('fontSize.xl');
  --h5-line-height: theme('lineHeight.sm');

  --h6-font-size: theme('fontSize.lg');
  --h6-line-height: theme('lineHeight.base');

  /* Section header text */
  --section-title-font-size-min: theme('fontSize.2xl');
  --section-title-font-size-scaled: 10vw;
  --section-title-font-size-max: theme('fontSize.7xl');
  --section-title-line-height: theme('lineHeight.4xs');

  /* Paragraphs */
  --body-text-color: var(--text-color-gray);

  /* COMPONENTS ============================================================= */

  /* Controls (Inputs & buttons) */
  --control-border-radius: theme('borderRadius.DEFAULT');
  --control-font-size: theme('fontSize.lg');
  --control-height: calc(var(--control-font-size) * (10 / 3));
  --control-padding-y: 0.5em;

  /* Buttons */
  --button-bg-color-default: var(--darkblue-500);
  --button-bg-color-default-hover: var(--darkblue-600);
  --button-bg-color-default-active: var(--darkblue-700);
  --button-text-color-default: white;
  --button-padding-x: 1.5em;
  --button-padding-y: 0.5em;

  /* Cards */
  --card-border-radius: theme('borderRadius.DEFAULT');
  --card-padding-x-max: theme('spacing.80');
  --card-padding-x-fluid: 9%;
  --card-padding-x-min: theme('spacing.30');
  --card-padding-y-max: theme('spacing.80');
  --card-padding-y-fluid: 12%;
  --card-padding-y-min: theme('spacing.30');
  --card-padding: clamp(
      var(--card-padding-y-min),
      var(--card-padding-y-fluid),
      var(--card-padding-y-max)
    )
    clamp(
      var(--card-padding-x-min),
      var(--card-padding-x-fluid),
      var(--card-padding-x-max)
    );

  /* Content containers */
  --content-padding-x: theme('spacing.20');
  --content-padding-x--lg: theme('spacing.30');

  /* Overlap */
  --overlap-spacing: theme('spacing.80');

  /* Nav */
  --nav-height: theme('spacing.80');

  /* Sections */
  --section-spacing: theme('spacing.60');
  --section-padding-y: var(--section-spacing);
  --section-header-margin-bottom: var(--section-spacing);

  /* THEMES ================================================================= */

  /* Dark */
  --theme-dark-bg: var(--darkblue-500);

  /* Light */
  --theme-light-bg: var(--lightgray-200);

  /* MEDIA QUERIES ========================================================== */

  @media (min-width: theme('screens.tablet-md')) {
    --section-spacing: theme('spacing.100');
  }
}
